var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "b-container",
        { staticClass: "mt-2" },
        [
          _c(
            "masthead",
            { attrs: { title: "Forms Library", "show-search": false } },
            [
              _c(
                "b-button",
                {
                  class: _vm.$mq != "sm" ? "btn-shadow mr-2" : "",
                  attrs: { variant: "link" },
                  on: { click: _vm.goBack },
                },
                [
                  _c("feather-icon", { attrs: { type: "chevron-left" } }),
                  _vm._v("\n      Back\n    "),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c(
            "div",
            { staticClass: "outer-container" },
            [
              _c(
                "b-card",
                {
                  staticClass: "filing-method-containers",
                  staticStyle: { "max-width": "90rem" },
                  attrs: { "no-body": "", header: "Documents" },
                },
                [
                  _vm.filingSteps.length > 0
                    ? _c(
                        "b-list-group",
                        { staticClass: "list-group" },
                        _vm._l(_vm.filingSteps, function (filingStep) {
                          return _c(
                            "b-list-group-item",
                            { key: filingStep.id },
                            [
                              filingStep.resource.kind === "url"
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.view(filingStep)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(filingStep.name) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              filingStep.resource.kind === "document"
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.open(
                                            filingStep.resource.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(filingStep.name) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "b-modal",
                                {
                                  ref: filingStep.resource.id,
                                  refInFor: true,
                                  attrs: {
                                    size: "xl",
                                    title: filingStep.resource.name,
                                    "ok-only": true,
                                  },
                                },
                                [
                                  _c("v1-resource-pdf-viewer", {
                                    attrs: {
                                      resource: filingStep.resource,
                                      "company-id": _vm.companyId,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("agency-contact-info", { attrs: { agency: _vm.agency() } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }