<template>
  <b-container v-if="loaded" class="mt-2">
    <masthead title="Forms Library" :show-search="false">
      <b-button variant="link" :class="$mq != 'sm' ? 'btn-shadow mr-2' : ''" @click="goBack">
        <feather-icon type="chevron-left" />
        Back
      </b-button>
    </masthead>
    <br>
    <div class="outer-container">
      <b-card
        no-body
        class="filing-method-containers"
        :header="'Documents'"
        style="max-width: 90rem;"
      >
        <b-list-group v-if="filingSteps.length > 0" class="list-group">
          <b-list-group-item v-for="filingStep in filingSteps" :key="filingStep.id">
            <b-button v-if="filingStep.resource.kind === 'url'"
                      variant="link"
                      @click="view(filingStep)"
            >
              {{ filingStep.name }}
            </b-button>
            <b-button v-if="filingStep.resource.kind === 'document'"
                      variant="link"
                      @click="open(filingStep.resource.id)"
            >
              {{ filingStep.name }}
            </b-button>

            <b-modal :ref="filingStep.resource.id" size="xl" :title="filingStep.resource.name" :ok-only="true">
              <v1-resource-pdf-viewer :resource="filingStep.resource" :company-id="companyId" />
            </b-modal>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </div>

    <br>
    <br>
    <br>
    <agency-contact-info :agency="agency()" />
  </b-container>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'
import V1ResourcePdfViewer from '@/components/V1ResourcePDFViewer'
import AgencyContactInfo from '@/components/Filing/AgencyContactInfo'
import Masthead from '../../components/shared/Masthead'
import axiosClient from '../../http'

export default {
  name: 'ResourceSelection',
  components: {
    AgencyContactInfo,
    Masthead,
    FeatherIcon,
    V1ResourcePdfViewer,
  },
  data() {
    return {
      // Refer to these as filingSteps universally so as not to cause confusion with StagelineSteps
      filingSteps: [],
      filing: null,
      companyId: this.$route.params.companyId,
      filingId: this.$route.params.filingId,
      filingName: null,
      loaded: false,
    }
  },
  async mounted() {
    await this.setFilingSteps()
    await this.receiveFiling()

    this.loaded = true
  },
  methods: {
    async setFilingSteps() {
      const response = await axiosClient.get(`client/filing_steps?filing_method_id=${this.$route.params.filingMethodId}`)

      this.filingSteps = response.data.result
    },
    async receiveFiling() {
      const response = await this.$store.dispatch('filingMethods/fetchFilingAndAgency', {
        params: {
          filingId: this.filingId,
        },
      })
      this.filing = response.data
    },
    agency(){
      return this.filing.agency
    },
    view(filingStep) {
      window.open(filingStep.resource.url)
    },
    open(resourceId) {
      this.$refs[resourceId][0].show()
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped></style>
