<template>
  <table v-if="agency" class="table">
    <thead>
      <tr>
        <th scope="col">
          {{ agency.name }}
        </th>
        <th scope="col" />
      </tr>
    </thead>
    <tbody>
      <template v-if="agency.agencyAddresses">
        <tr v-for="address in agency.agencyAddresses.filter((item) => item.line1)" :key="address.id">
          <td>{{ address.label }}</td>
          <td>
            {{ address.line1 }}
            <span v-if="address.line2">{{ address.line2 }}</span>,
            {{ address.city }}
            {{ stateAbbr }}
            {{ address.zipPostalCode }}
          </td>
        </tr>
      </template>
      <template v-if="agency.agencyPhones">
        <tr v-for="phone in agency.agencyPhones" :key="phone.id">
          <td>{{ phone.label }}</td>
          <td>
            <span>{{ phone.phoneNumber }}</span>
          </td>
        </tr>
      </template>
      <template v-if="agency.agencyEmails">
        <tr v-for="email in agency.agencyEmails" :key="email.id">
          <td>{{ email.label }}</td>
          <td>
            <span>{{ email.emailAddress }}</span>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { usStates } from '@/common/modules/usStates'

  export default {
    name: 'AgencyContactInfo',
    props: {
      agency: null,
    },
    computed: {
      stateAbbr() {
        const statesAbbr = usStates.find((state) => state.stateProvinceRegion === this.agency.jurisdiction.stateProvinceRegion)
        return statesAbbr?.abbreviation || this.agency.jurisdiction.stateProvinceRegion
      },
    },
  }
</script>
