var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.agency
    ? _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("\n        " + _vm._s(_vm.agency.name) + "\n      "),
            ]),
            _c("th", { attrs: { scope: "col" } }),
          ]),
        ]),
        _c(
          "tbody",
          [
            _vm.agency.agencyAddresses
              ? _vm._l(
                  _vm.agency.agencyAddresses.filter((item) => item.line1),
                  function (address) {
                    return _c("tr", { key: address.id }, [
                      _c("td", [_vm._v(_vm._s(address.label))]),
                      _c("td", [
                        _vm._v(
                          "\n          " +
                            _vm._s(address.line1) +
                            "\n          "
                        ),
                        address.line2
                          ? _c("span", [_vm._v(_vm._s(address.line2))])
                          : _vm._e(),
                        _vm._v(
                          ",\n          " +
                            _vm._s(address.city) +
                            "\n          " +
                            _vm._s(_vm.stateAbbr) +
                            "\n          " +
                            _vm._s(address.zipPostalCode) +
                            "\n        "
                        ),
                      ]),
                    ])
                  }
                )
              : _vm._e(),
            _vm.agency.agencyPhones
              ? _vm._l(_vm.agency.agencyPhones, function (phone) {
                  return _c("tr", { key: phone.id }, [
                    _c("td", [_vm._v(_vm._s(phone.label))]),
                    _c("td", [_c("span", [_vm._v(_vm._s(phone.phoneNumber))])]),
                  ])
                })
              : _vm._e(),
            _vm.agency.agencyEmails
              ? _vm._l(_vm.agency.agencyEmails, function (email) {
                  return _c("tr", { key: email.id }, [
                    _c("td", [_vm._v(_vm._s(email.label))]),
                    _c("td", [
                      _c("span", [_vm._v(_vm._s(email.emailAddress))]),
                    ]),
                  ])
                })
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }